import { Page1, Page2, Page3, Page4, Page5, Page6 } from "./home-items/index";

const Home = () => {
  return (
    <div className="w-full">
      <Page1 />
      <Page2 />
      <Page3 />
      <Page4 />
      <Page5 />
      <Page6 />
    </div>
  );
};

export default Home;
