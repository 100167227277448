import Header from "./components/Header";
import Section from "./components/Section";
import CTA from "../../../constant/CTA";

var AboutUs = () => {
  return (
    <div className="pt-10">
      <Header />
      <Section />
      <CTA />
      {/* <Footer/> */}
    </div>
  );
};

export default AboutUs;
