export const headerStyle = {
  container: "px-5 pb-5 flex flex-col justify-center mb-4 items-center",
  parent: "uni text-center md:text-6xl text-3xl py-5 md:pb-10 mb-4 font-bold",
  blueText: "text-tblue",
  greenText: "text-tgreen",
};
export const main = {
  wraper: "pt-10",
};

export const container = {
  containerGrid: "flex md:gap-[1rem] md:justify-between w-[90%] m-auto",
  leftGrid: "hidden xl:block sm:flex-[0.4] h-auto p-4 shadow-md md:w-[30%]",
  rightGrid: "flex-[1.5] pl-4 overflow-auto",
};
