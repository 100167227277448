const Header = () => {
  return (
    <header className="uni text-center md:text-6xl text-3xl font-bold md:py-20 py-10">
      <span className=" text-tblue">CONTACT</span>
      <span className=" text-tgreen"> US</span>
    </header>
  );
};

export default Header;
