import Header from "./components/Header";
import Form from "./components/Form";
import Footer from "../About/components/Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <Form />
      <Footer />
    </>
  );
};

export default ContactUs;
