import { JumbotronSection } from "./JumbotronSection";
import { Partnerships } from "./Partnership";
import { ProgrammesSlider } from "./ProgrammesSlider";

export const Impact = () => {
  return (
    <>
      <JumbotronSection />
      <ProgrammesSlider />
      <Partnerships />
    </>
  );
};
